// status of Procedure
// https://www.hl7.org/fhir/valueset-event-status.html

const PROCEDURE_STATUS = {
    PREPARATION: "Procedure.preparation",
    IN_PROGRESS: "Procedure.in-progress",
    NOT_DONE: "Procedure.not-done",
    ON_HOLD: "Procedure.on-hold",
    STOPPED: "Procedure.stopped",
    COMPLETED: "Procedure.completed",
    ENTERED_IN_ERROR: "Procedure.entered-in-error",
    UNKNOWN: "Procedure.unknown"
};

// status of Appointment
// https://www.hl7.org/fhir/valueset-appointmentstatus.html

export const APPOINTMENT_STATUS = {
    PROPOSED: "Appointment.proposed",
    PENDING: "Appointment.pending",
    BOOKED: "Appointment.booked",
    ARRIVED: "Appointment.arrived",
    FULFILLED: "Appointment.fulfilled",
    CANCELLED: "Appointment.cancelled",
    NOSHOW: "Appointment.noshow",
    ENTERED_IN_ERROR: "Appointment.entered-in-error",
    CHECKED_IN: "Appointment.checked-in",
    WAITLIST: "Appointment.waitlist"
};

// status of ServiceRequest
// http://hl7.org/fhir/valueset-request-status.html

export const SERVICEREQUEST_STATUS = {
    DRAFT: "ServiceRequest.draft",
    ACTIVE: "ServiceRequest.active",
    ON_HOLD: "ServiceRequest.on-hold",
    REVOKED: "ServiceRequest.revoked",
    COMPLETED: "ServiceRequest.completed",
    ENTERED_IN_ERROR: "ServiceRequest.entered-in-error",
    UNKNOWN: "ServiceRequest.unknown"
};

export const ErrorUnpublishedAppointment = "ErrorUnpublishedAppointment";

const preparationStatusOpManage = [APPOINTMENT_STATUS.PENDING, APPOINTMENT_STATUS.BOOKED, PROCEDURE_STATUS.PREPARATION];
const inProgressStatus = PROCEDURE_STATUS.IN_PROGRESS;
const completedStatus = PROCEDURE_STATUS.COMPLETED;
const preparationStatusDayView = [SERVICEREQUEST_STATUS.ACTIVE, APPOINTMENT_STATUS.BOOKED, PROCEDURE_STATUS.PREPARATION];

// Status key for tabs/status in op backlog, used also translation key.
// Also is important to notice that the order of the keys is the order of the tabs in the op backlog.
const STATUS_KEY = Object.freeze({
    PLANNED: "planned",
    ON_HOLD: "onHold",
    CANT_PLAN: "cantPlan",
    IN_PROGRESS: "started",
    DONE: "done",
    PERMANENTLY_REMOVED: "permanentlyRemoved",
    INCONSISTENT: "inconsistent",
    ALL: "all"
});

const EXCLUDE_TAB = "INCONSISTENT";
const CANT_PLAN_TAB = "CANT_PLAN";

// The selectable status in the edit layer
const /** @type string[] */ SELECTABLE_STATUS = [STATUS_KEY.PLANNED, STATUS_KEY.ON_HOLD];
export {
    preparationStatusOpManage,
    preparationStatusDayView,
    inProgressStatus,
    completedStatus,
    PROCEDURE_STATUS,
    STATUS_KEY,
    SELECTABLE_STATUS,
    EXCLUDE_TAB,
    CANT_PLAN_TAB
};
