/**
 * @fileoverview security provider
 *
 * usage:
 * <code>
 *     const permissions = [...];
 *
 *     <SecurityProvider permissions={permissions}>
 *         <App />
 *     </SecurityProvider>
 * </code>
 */

import PropTypes from "prop-types";
import React from "react";

import SecurityContext from "./security_context";

const SecurityProvider = ({children, permissions}) => {
    const isGranted = (permission) => permissions.includes(permission);

    return (
        <SecurityContext.Provider
            value={{
                permissions,
                isGranted
            }}
        >
            {children}
        </SecurityContext.Provider>
    );
};

SecurityProvider.propTypes = {
    children: PropTypes.node.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string)
};

SecurityProvider.defaultProps = {
    permissions: []
};

export default SecurityProvider;
